<template>
<div>
  <b-container fluid style="background-image: url('/img/ai-background3.jpg'); background-repeat: no-repeat; background-size: cover; height: 450px; margin: 0px;">
    <b-row>
      <b-col style="color: white;">
        <b-container>
          <b-row>
            <b-col>
              <h1 class="kstyle" style="font-size: 42px; font-weight: 500; margin-top: 150px;">Market profile</h1>
              <p style="font-size: 18px;">Explore AI related regulations, news and insights in the market of your choice</p>
            </b-col>
          </b-row>
          <b-row class="my-4">
            <b-col style="font-size: 18px;">
              <b-form inline>
                Select market: <b-form-select class="mx-4" v-model="countrySelected" :options="countryOptions" size="lg" style="font-weight: 700"></b-form-select>
              </b-form>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
  <b-container fluid v-if="show" class="mt-4">
    <b-row class="mb-4">
      <b-col class="mb-4">
        <h2 class="mb-4" style="font-size: 20px; font-weight: bold;">Regulations</h2>
        <div v-for="item in initiatives" :key="item.id">
          <initiative :item="item" :trim="400"></initiative>
        </div>
      </b-col>
      <b-col class="mb-4">
        <div class="mb-5" v-if="!loadingNews && news.length > 0">
          <h2 class="mb-4" style="font-size: 20px; font-weight: bold;">External News</h2>
          <div class="p-3 bg-white shadow">
            <div v-for="item in news" :key="'news'+item.id" class="my-3">
              <div><a :href="item.link">{{item.name}}</a></div>
              <div><small>{{item.publisher}} - {{moment(item.date).format('YYYY-MM-DD')}}</small></div>
            </div>
          </div>
        </div>
        <div v-if="!loadingNews && articles.length > 0">
          <h2 class="my-4" style="font-size: 20px; font-weight: bold;">KPMG Insights</h2>
          <div>
            <div v-for="item in articles" :key="'articles'+item.id" class="my-3">
              <b-container class="shadow p-5 bg-white">
                <b-row>
                  <b-col class="text-center" lg="4" sm="12">
                    <div>
                      <div :style="item.style"></div>
                    </div>
                  </b-col>
                  <b-col lg="8" sm="12">
                    <div><b-link :to="{ name: 'AiInsight', params: {id: item.id }}"><strong>{{item.title}}</strong></b-link></div>
                    <div class="mb-2"><small>{{moment(item.approved_at).format('YYYY-MM-DD')}} by {{ item.approved_by }}</small></div>
                    <div>{{ item.summary }}</div>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </div>
        </div>
        <div class="my-3" v-if="loadingNews">
          <b-spinner label="checking news..." />
          loading news...
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import Initiative from '@/components/InitiativeAi.vue'

// create delay function
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms))

function getUsernameFromEmail (email) {
  const match = email.match(/^[^@]+/)
  return match ? match[0].replace(/\./g, '') : null
}

export default {
  name: 'AiCountry',
  components: {
    Initiative
  },
  computed: {
    countryOptions: function () {
      const all = this.$store.state.initiativesAi
      let results = all.map(x => x.country)
      results = _.uniq(results)
      results.sort()
      return results
    },
    rows: function () {
      const country = this.$route.params.country
      const results = []
      const all = this.$store.state.initiativesAi
      all.forEach(x => {
        if (x.country === country) {
          results.push(x)
        }
      })
      return results
    },
    initiatives: function () {
      // from this rows filter rows where country === this.countrySelected
      const results = []
      this.rows.forEach(x => {
        if (x.country === this.countrySelected) {
          results.push(x)
        }
      })
      return results
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.$stat.log({ page: 'ai country', action: 'open ai country' })
    document.title = "AI Regulatory Radar: " + this.$route.params.country
    if (this.$route.params.country) {
      this.countrySelected = this.$route.params.country
      window.localStorage.setItem('frmCloudCountrySelected', this.countrySelected)
    } else if (window.localStorage.getItem('frmCloudCountrySelected')) {
      this.countrySelected = window.localStorage.getItem('frmCloudCountrySelected')
    } else {
      this.countrySelected = this.countryOptions[0]
    }
    await delay(100)
    this.show = true
    await this.loadNews()
  },
  data () {
    const data = {
      articles: [],
      countrySelected: null,
      loadingNews: true,
      moment: moment,
      news: [],
      show: false
    }
    return data
  },
  methods: {
    loadNews: async function () {
      this.loadingNews = true
      try {
        this.news = await this.$Amplify.API.get('cosmos', `/news/country/${this.countrySelected}/topic/154`)
        const articles = await this.$Amplify.API.get('cosmos', `/articles/country/${this.countrySelected}/topic/154`)
        articles.forEach(x => {
          const img = getUsernameFromEmail(x.approved_by)
          x.img = img
          x.style = `margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/${img}.jpg'); background-position: center; background-size: cover; width: 153px; height: 153px;`
        })
        this.articles = articles
        this.loadingNews = false
      } catch (e) {
        this.$logger.warn(e)
      }
    }
  },
  watch: {
    '$route.params.country': function () {
      this.countrySelected = this.$route.params.country
      document.title = "AI Regulatory Radar: " + this.$route.params.country
    },
    'countrySelected': async function () {
      window.localStorage.setItem('frmCloudCountrySelected', this.countrySelected)
      if (this.countrySelected !== this.$route.params.country) {
        this.$router.push({ name: 'AiCountry', params: { country: this.countrySelected } })
        this.show = false
        this.loadNews()
        await delay(100)
        this.show = true
      }
    }
  }
}
</script>

<style>
</style>
